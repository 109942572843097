/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components), {Tabs, Tab, Specialization, Boon, Skill, Trait, Item, Divider, Grid, GridItem, Traits, Card, Control, Condition, Instability, Weapons, Information} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Card) _missingMdxReference("Card", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Control) _missingMdxReference("Control", true);
  if (!Divider) _missingMdxReference("Divider", true);
  if (!Grid) _missingMdxReference("Grid", true);
  if (!GridItem) _missingMdxReference("GridItem", true);
  if (!Information) _missingMdxReference("Information", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Traits) _missingMdxReference("Traits", true);
  if (!Weapons) _missingMdxReference("Weapons", true);
  return React.createElement(Tabs, null, React.createElement(Tab, {
    specialization: "Warrior",
    title: "Build"
  }, React.createElement(_components.p, null, "The ", React.createElement(Specialization, {
    text: "Banner Warrior",
    name: "Warrior"
  }), " is the support build for warriors in fractals. It provides a lot less DPS compared to ", React.createElement(Specialization, {
    name: "Berserker"
  }), ". In PuGs however, where CC or ", React.createElement(Boon, {
    name: "Might"
  }), " is the limiting factor it can be beneficial to bring ", React.createElement(Specialization, {
    text: "Banner Warrior",
    name: "Warrior"
  }), " over ", React.createElement(Specialization, {
    name: "Berserker"
  }), "."), React.createElement(_components.p, null, "Besides providing the group with strong unique damage buffs (", React.createElement(Skill, {
    id: "14405",
    id: "14405"
  }), ", ", React.createElement(Skill, {
    id: "14407",
    id: "14407"
  }), " and ", React.createElement(Trait, {
    id: "1482",
    id: "1482"
  }), ") and having excellent defiance bar damage with the Mace/Mace set, the build also has a medium burst damage potential on both small and large hitboxes for a support role."), React.createElement(_components.p, null, "If enemy boons are present and for some reason you are still not playing the ", React.createElement(Specialization, {
    name: "Renegade"
  }), " + ", React.createElement(Specialization, {
    name: "Firebrand"
  }), " composition, the ", React.createElement(Specialization, {
    name: "Spellbreaker"
  }), " specialization can be played to reliably remove boons with ", React.createElement(Skill, {
    id: "45252",
    id: "45252"
  }), " and ", React.createElement(Trait, {
    id: "2162",
    id: "2162"
  }), ". Another advantage of this variation is the strong on-demand projectile destruction with ", React.createElement(Skill, {
    id: "45333",
    id: "45333"
  }), "."), React.createElement(_components.p, null, "Furthermore, the build is able to use damage modifying sigils like ", React.createElement(Item, {
    id: "24868",
    id: "24868"
  }), " as well as slaying potions such as ", React.createElement(Item, {
    id: "50082",
    id: "50082"
  }), ". As a support warrior, your focus is first and foremost providing the group with aforementioned damage buffs and removing boons, secondly breaking the defiance bar while following your optimized damage rotation."), React.createElement(Divider, {
    text: "Equipment"
  }), React.createElement(Divider, {
    text: "Build"
  }), React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "7"
  }, React.createElement(Traits, {
    traits1Id: "4",
    traits1: "Strength",
    traits1Selected: "Peak Performance, Forceful Greatsword, Berserkers Power",
    traits2: "Tactics",
    traits2Selected: "Legspecialist, Empower Allies, Phalanx Strength",
    traits3Id: "51",
    traits3: "Discipline",
    traits3SelectedIds: "1413,1484,1369",
    traits1Id: "4",
    traits3Id: "51",
    traits3SelectedIds: "1413,1484,1369",
    traits1SelectedIds: "1444,1338,1437",
    traits2Id: "11",
    traits2SelectedIds: "1469,1482,1711"
  })), React.createElement(GridItem, {
    sm: "5"
  }, React.createElement(Card, {
    title: "Swap Weapons"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Axes with ", React.createElement(Item, {
    name: "Night",
    type: "Sigil",
    disableText: true,
    id: "36053"
  }), ", ", React.createElement(Item, {
    name: "Serpent Slaying",
    type: "Sigil",
    disableText: true,
    id: "24658"
  }), " and slaying sigils"), "\n", React.createElement(_components.li, null, "A warhorn for pre-stacking"), "\n", React.createElement(_components.li, null, "Having a sword is very useful and sometimes even necessary especially for some of the skips (Uncategorized Fractal | Urban Battleground)"), "\n", React.createElement(_components.li, null, "If no additional cc is necessary you can use Greatsword instead of Mace/Mace"), "\n")), React.createElement(Card, {
    title: "Situational"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14354",
    size: "big",
    disableText: true,
    id: "14354"
  })), React.createElement(_components.td, null, "Please use this instead of ", React.createElement(Skill, {
    id: "14502",
    id: "14502"
  }), " for situations where you should not ", React.createElement(Control, {
    name: "Knockback"
  }), " foes (especially trash mobs).")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14419",
    size: "big",
    disableText: true,
    id: "14419"
  })), React.createElement(_components.td, null, "A niche skill if you expect people do go down from incoming damage. Also a good precast to provide more ", React.createElement(Boon, {
    name: "Might"
  }), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Signet of Rage",
    size: "big",
    disableText: true,
    id: "14355"
  })), React.createElement(_components.td, null, "A nice ", React.createElement(Boon, {
    name: "Might"
  }), " boost for the party after intermediate phases (e.g. orbs at Ensolyss).")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "12363",
    size: "big",
    disableText: true,
    id: "12363"
  })), React.createElement(_components.td, null, "Meme DPS."))))))), React.createElement(Divider, {
    text: "Might Sharing Variant"
  }), React.createElement(_components.p, null, "If you're running without a ", React.createElement(Specialization, {
    name: "Druid"
  }), " or ", React.createElement(Specialization, {
    name: "Renegade"
  }), " (for whatever unlogical reason), you need to provide ", React.createElement(Boon, {
    name: "Might"
  }), " (and ", React.createElement(Boon, {
    name: "Fury"
  }), " to some degree) for the party. This is achieved with the following changes to the build:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "6x ", React.createElement(Item, {
    name: "Strength",
    type: "Rune",
    id: "24714"
  }), " instead of ", React.createElement(Item, {
    name: "Scholar",
    type: "Rune",
    id: "24836"
  })), "\n", React.createElement(_components.li, null, React.createElement(Item, {
    name: "Fried Golden Dumpling",
    type: "Food",
    id: "68633"
  }), " instead of ", React.createElement(Item, {
    id: "41569",
    id: "41569"
  })), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "For Great Justice",
    id: "14403"
  }), " instead of ", React.createElement(Skill, {
    id: "14502",
    id: "14502"
  })), "\n"), React.createElement(_components.p, null, "Everything else stays the same."), React.createElement(Divider, {
    text: "Details"
  }), React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "8"
  }, React.createElement(Card, {
    title: "Skill usage"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Pre-fight:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    id: "14394",
    id: "14394"
  }), " and ", React.createElement(Skill, {
    id: "14393",
    id: "14393"
  }), " on warhorn (", React.createElement(Boon, {
    name: "Vigor",
    disableText: true
  }), React.createElement(Boon, {
    name: "Swiftness",
    disableText: true
  }), ") to blast a fire field for ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n", React.createElement(_components.li, null, "Place ", React.createElement(Skill, {
    id: "14407",
    id: "14407"
  }), " and ", React.createElement(Skill, {
    id: "14405",
    id: "14405"
  }), " into to the boss"), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    id: "14402",
    id: "14402"
  }), " to charge up Adrenaline immediately before the fight starts"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Breaking defiance bars:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If there is a defiance bar present immediately at the start of the fight, start on your Mace/Mace set - otherwise swap to it when necessary"), "\n", React.createElement(_components.li, null, "With ", React.createElement(Item, {
    id: "24639",
    id: "24639"
  }), ", you will deal over 940 defiance bar damage with the following skill order:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "14415",
    id: "14415"
  }), ", you can precast it and cancel the aftercast animation"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    id: "14503",
    id: "14503"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    id: "14414",
    id: "14414"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    id: "14502",
    id: "14502"
  }), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "This also grants 1-3 stacks of ", React.createElement(Trait, {
    id: "1437",
    id: "1437"
  }), ", ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), " and ", React.createElement(Trait, {
    id: "1444",
    id: "1444"
  })), "\n"), "\n"), "\n", React.createElement(_components.li, null, "DPS:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Camp Axe/Axe if there is no CC needed"), "\n", React.createElement(_components.li, null, "Damage modifiers:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Make sure to use ", React.createElement(Skill, {
    id: "14353",
    id: "14353"
  }), " when you are at full adrenaline to gain three stacks of ", React.createElement(Trait, {
    id: "1437",
    id: "1437"
  })), "\n", React.createElement(_components.li, null, "Trigger ", React.createElement(Trait, {
    id: "1444",
    id: "1444"
  }), " by using ", React.createElement(Skill, {
    id: "14502",
    id: "14502"
  }), ". Adds 20% damage but keep in mind that it does not stack"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    id: "14399",
    id: "14399"
  }), " while all modifiers are up"), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    id: "14421",
    id: "14421"
  }), ", ", React.createElement(Skill, {
    id: "14398",
    id: "14398"
  }), " and ", React.createElement(Skill, {
    id: "14418",
    id: "14418"
  }), " off recharge"), "\n", React.createElement(_components.li, null, "Don't cancel the third hit of your auto-attack chain as it does more damage than the first two combined"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Keep in mind that ", React.createElement(Skill, {
    id: "14518",
    id: "14518"
  }), " inflicts 15x ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " if your party is running low on it"), "\n"), React.createElement(_components.p, null, "If the Mistlock Singularity is easily accessible and close to the encounter:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "for very short encounters (< 50 seconds) pre cast ", React.createElement(Skill, {
    id: "14407",
    id: "14407"
  }), " ", React.createElement(Skill, {
    id: "14405",
    id: "14405"
  }), " ", React.createElement(Skill, {
    id: "14408",
    id: "14408"
  }), " or ", React.createElement(Skill, {
    id: "14528",
    id: "14528"
  }), " and ", React.createElement(Skill, {
    id: "14419",
    id: "14419"
  }), " and use ", React.createElement(Skill, {
    id: "14516",
    id: "14516"
  }), " and ", React.createElement(Skill, {
    id: "14410",
    id: "14410"
  }), " instead of the usual ", React.createElement(Skill, {
    id: "14407",
    id: "14407"
  }), " and ", React.createElement(Skill, {
    id: "14405",
    id: "14405"
  }), ". Activate ", React.createElement(Skill, {
    id: "14410",
    id: "14410"
  }), " during burst phase (e.g. right before using ", React.createElement(Skill, {
    id: "14399",
    id: "14399"
  }), ")"), "\n", React.createElement(_components.li, null, "for longer encounters (>50 seconds) use e.g. ", React.createElement(Skill, {
    id: "12363",
    id: "12363"
  }), " before picking up the Mistlock Singularity"), "\n"), React.createElement(_components.p, null, "Several Warrior skills involving movement and or leaps such as ", React.createElement(Skill, {
    id: "14446",
    id: "14446"
  }), ", ", React.createElement(Skill, {
    id: "14447",
    id: "14447"
  }), " ", React.createElement(Skill, {
    id: "45252",
    id: "45252"
  }), " ", React.createElement(Skill, {
    id: "46233",
    id: "46233"
  }), " ", React.createElement(Skill, {
    id: "14424",
    id: "14424"
  }), " ", React.createElement(Skill, {
    id: "14502",
    id: "14502"
  }), " and ", React.createElement(Skill, {
    id: "14366",
    id: "14366"
  }), " cannot be canceled by stow/draw weapons but by using weapon swap."))), React.createElement(GridItem, {
    sm: "4"
  }, React.createElement(Card, {
    title: "CC skills"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14502",
    id: "14502"
  })), React.createElement(_components.td, null, "150 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14414",
    id: "14414"
  })), React.createElement(_components.td, null, "390 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14415",
    id: "14415"
  })), React.createElement(_components.td, null, "300 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14503",
    id: "14503"
  })), React.createElement(_components.td, null, "100 damage")))), React.createElement(_components.p, null, "If using ", React.createElement(Skill, {
    id: "14483",
    id: "14483"
  }), ":"), React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14490",
    id: "14490"
  })), React.createElement(_components.td, null, "260 damage (while on Mace set)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14556",
    id: "14556"
  })), React.createElement(_components.td, null, "200 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14488",
    id: "14488"
  })), React.createElement(_components.td, null, "150 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14487",
    id: "14487"
  })), React.createElement(_components.td, null, "100 damage")))), React.createElement(_components.p, null, "With mainhand dagger on ", React.createElement(Specialization, {
    name: "Spellbreaker"
  }), ":"), React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "44937",
    id: "44937"
  })), React.createElement(_components.td, null, "100 damage (or 150 on targets using skills)")))))))), React.createElement(Tab, {
    specialization: "spellbreaker",
    title: "Spellbreaker"
  }, React.createElement(Divider, {
    text: "Spellbreaker Variant"
  }), React.createElement(_components.p, null, "With enemy boons present (", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), ", Swampland, Molten Boss, Twilight Oasis) and no ", React.createElement(Specialization, {
    text: "Domination Chronomancer",
    name: "Chronomancer"
  }), " or ", React.createElement(Specialization, {
    text: "Mallyx Renegade",
    name: "Renegade"
  }), " you will have to take the ", React.createElement(Specialization, {
    name: "Spellbreaker"
  }), " specialization instead of ", React.createElement(_components.strong, null, "Discipline"), "."), React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "7"
  }, React.createElement(Traits, {
    traits1: "Spellbreaker",
    traits1SelectedIds: "2107,2126,2060",
    traits1SelectedIds: "2107,2126,2060",
    traits1Id: "61"
  })), React.createElement(GridItem, {
    sm: "5"
  }, React.createElement(Weapons, {
    weapon1MainType: "Dagger",
    weapon1MainAffix: "Berserker",
    weapon1MainSigil1: "Force",
    weapon1OffType: "Axe",
    weapon1OffAffix: "Berserker",
    weapon1OffSigil: "Impact",
    weapon1MainId: "46760",
    weapon1MainSigil1Id: "24615",
    weapon1OffId: "46759",
    weapon1OffSigilId: "24868"
  }), React.createElement(Information, null, React.createElement(_components.p, null, "Offhand weapons are still Mace/Mace!")))), React.createElement(_components.p, null, "You should also take ", React.createElement(Skill, {
    id: "45333",
    id: "45333"
  }), " where necessary. You can consider ", React.createElement(Item, {
    id: "72872",
    id: "72872"
  }), " for additional party ", React.createElement(Boon, {
    name: "Might"
  }), "."), React.createElement(_components.p, null, "Gear-wise you are losing Power, Precision, and Ferocity from Discipline's trait ", React.createElement(Trait, {
    id: "1484",
    id: "1484"
  }), ". To make up for this we are recommending to use our ", React.createElement(_components.a, {
    href: "http://old.discretize.eu"
  }, "Gear-optimiser"), " on the website if you want to max out crit cap."), React.createElement(_components.p, null, "Everything else stays the same."), React.createElement(Divider, {
    text: "Guide"
  }), React.createElement(Card, {
    title: "Spellbreaker variant"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Evolve your rotation around your highest burst damage skills: ", React.createElement(Skill, {
    id: "14399",
    id: "14399"
  }), " and ", React.createElement(Skill, {
    id: "14554",
    id: "14554"
  }), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Before using high burst damage skills:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Trigger ", React.createElement(Trait, {
    id: "1444",
    id: "1444"
  }), " by using ", React.createElement(Skill, {
    id: "14502",
    id: "14502"
  }), ". Adds 20% damage but keep in mind that it does not stack."), "\n", React.createElement(_components.li, null, "Have 1-3 stacks of ", React.createElement(Trait, {
    id: "1437",
    id: "1437"
  }), ". Remember that ", React.createElement(Skill, {
    id: "44165",
    id: "44165"
  }), " counts as a burst skill. Each stack adds another 7% up to 21% total (with 3 stacks)."), "\n", React.createElement(_components.li, null, "Trigger ", React.createElement(Trait, {
    id: "2060",
    id: "2060"
  }), " which goes hand in hand with ", React.createElement(Trait, {
    id: "1437",
    id: "1437"
  }), " and adds another 10% damage"), "\n", React.createElement(_components.li, null, "Have 1-5 stacks of ", React.createElement(Trait, {
    id: "2130",
    id: "2130"
  }), " adding 45 Power and Ferocity per stack"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "On ", React.createElement(_components.strong, null, "Greatsword"), ":", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Don't cancel your auto-attack as the third hit deals the most damage"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "14447",
    id: "14447"
  }), " is a DPS increase, especially against a wall"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "14510",
    id: "14510"
  }), " is worth using between auto-attack chains"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "On ", React.createElement(_components.strong, null, "Dagger/Axe"), ":", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    id: "44937",
    id: "44937"
  }), " preferably while the target is using a skill"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "14418",
    id: "14418"
  }), " is a DPS increase"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    id: "45333",
    id: "45333"
  }), " when there are multiple targets with boons (e.g. MAMA and Assault Knight at 25%), you are not at the target to remove boons in time (e.g. Artsariiv after the split 66% and 33%) or to destroy projectiles (e.g. Imbued Shaman initial Lava Elemental spawn at 75% and final 25%)"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Full Counter"), " (to trigger ", React.createElement(Trait, {
    id: "1437",
    id: "1437"
  }), "):", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    id: "44165",
    id: "44165"
  }), " block aspect acts similar to ", React.createElement(Boon, {
    name: "Aegis"
  }), ". Thus any attack you would be able to block with ", React.createElement(Boon, {
    name: "Aegis"
  }), " successfully activates ", React.createElement(Skill, {
    id: "44165",
    id: "44165"
  }), "."), "\n"), "\n"), "\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
